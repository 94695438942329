@media only screen and (min-width: 767px) {
  #MenuScroll {
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease; }

  .main-nav-scrolled {
    z-index: 1000;
    position: fixed;
    width: 100%;
    top: 0;
    padding: 0;
    bottom: inherit; } }
